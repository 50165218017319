<template>
  <vue-draggable-resizable
    class="planeWrap notCloseVideo_js"
    ref="planeWrap"
    :w="w"
    :h="h"
    :x="x"
    :y="y"
    :minWidth="160*2"
    :minHeight="90*2"
    :lock-aspect-ratio="true"
    :draggable="draggable"
    @resizestop="resizeStop"
    :onDrag="dragging"
  >
    <div ref="dragArea" class="dragArea notCloseVideo_js"></div>
    <div ref="youtubeParent" class="w-100 h-100 youtubeParent notCloseVideo_js">
      <youtube 
        ref="youtube"
        id="widget2"
        class="yt notCloseVideo_js" 
        width="100%" 
        height="100%" 
        frameborder="0" 
        sandbox="allow-scripts allow-same-origin" 
        :video-id="video_id" 
        :player-vars="playerVars"
        @ready="onPlayerReady"
        @playing="onPlayerStateChange"
        @paused="onPlayerStateChange"
      />

      <template v-if="showSubElement">
        <div 
          class="flex items-center justify-center z-10 w-1/5 h-full absolute top-0 left-0 cursor-pointer transition-all duration-500 opacity-0 move-back"
          :style="{
            background: 'linear-gradient(90deg, #FEFAB8 -3.88%, rgba(255, 252, 199, 0) 95.63%)',
            borderRadius: '10px 0px 0px 10px'
          }"
          @click.stop="setBackVideo"
        >
          <div class="flex items-center p-1 bg-white rounded">
            <img :src="require('@/assets/images/15sback.svg')">
            <p class="font-grey-dark-200 text-xs ml-1">-15S</p>
          </div>
        </div>
        <div 
          class="flex items-center justify-center w-full h-full absolute top-0 right-0 cursor-pointer transition-all duration-500 opacity-0 move-back"
        >
          <div 
            class="flex items-center justify-center p-1 bg-white rounded-full w-16 h-16"
            :style="{
              background: 'rgba(0, 0, 0, .5)'
            }"
          >
            <img v-if="playerState" :src="require('@/assets/images/video-play.svg')" class="select-none">
            <img v-else :src="require('@/assets/images/video-pause.svg')" class="select-none">
          </div>
        </div>
        <div 
          @click.stop="closeVideo"
          class="flex items-center z-10 justify-center w-6 h-6 rounded-full bg-gray-100 border-dgray absolute top-4 -right-3 cursor-pointer notCloseVideo_js"
        >
          <img :src="require('../../assets/icons/closed.svg')" alt="" class="notCloseVideo_js">
        </div>
        <div 
          @click.stop="showVideoDetails"
          class="flex items-center z-10 justify-center w-6 h-6 rounded-full bg-gray-100 border-dgray absolute top-12 -right-3 cursor-pointer notCloseVideo_js"
        >
          <img :src="require('../../assets/icons/edit.svg')" alt="" class="notCloseVideo_js">
        </div>
      </template>

      <div class="flex overflow-x-scroll no-scrollbar prompts" v-if="showPrompts">
        <!-- <div class="rounded px-2 mt-2 ml-2 cursor-pointer" style="background: #ECD0FC;" @click.stop="screencap">A</div> -->
        <span class="flex items-center rounded px-2 py-1 mt-2 cursor-pointer flex-shrink-0 text-sm border" style="background: #D9D9D9; border-color: #666666; line-height: 1.5rem;" @click="autoSummarize">
          <img :src="require('../../assets/icons/pen.svg')" class="select-none mr-2 w-6"/>Auto Summary
        </span>
        <span class="flex items-center rounded px-2 mt-2 ml-2 cursor-pointer flex-shrink-0 text-sm border" style="background: #ECD0FC; border-color: rgb(223 169 253); line-height: 1.5rem;">
          <img :src="require('../../assets/icons/question.svg')" class="select-none mr-2 w-6"/>Is there violence?
        </span>
        <span class="flex items-center rounded px-2 mt-2 ml-2 cursor-pointer flex-shrink-0 text-sm border" style="background: #ECD0FC; border-color: rgb(223 169 253); line-height: 1.5rem;">
          <img :src="require('../../assets/icons/question.svg')" class="select-none mr-2 w-6"/>Where is this creator?
        </span>
        <span class="flex items-center rounded px-2 mt-2 ml-2 cursor-pointer flex-shrink-0 text-sm border mr-20" style="background: #ECD0FC; border-color: rgb(223 169 253); line-height: 1.5rem;">
          <img :src="require('../../assets/icons/question.svg')" class="select-none mr-2 w-6"/>Who are in this video?
        </span>
      </div>

    </div>
    <div v-if="showSubElement" class="absolute bottom-0 pb-2 w-full px-2 transition-all duration-500 progressBarWrap notCloseVideo_js z-50"
      style="background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.88) 100%);">
      <div 
        @mousedown="rangeStop"
        @mouseup="rangePlay"
        class="notCloseVideo_js"
      >
        <input
          v-model="playerTimeDifference"
          @change="videoRange"
          type="range" 
          class="w-full notCloseVideo_js" 
          :min="0" 
          :max="100" 
          :step="1"
        >

        <div 
          class="w-full h-2 flex rounded-xl items-center content-center flex-row cursor-pointer" 
          style="background-color: #222222"
          v-on:mousemove="showSuggestion"
          @mouseleave="hideSuggestion"
          ref="autosummarybar"
        >
          <div class="auto-summary-bg h-2 rounded-xl ml-20 absolute" style="min-width: 3rem;" @click="showPromptsUI"></div>
          <div v-if="showSuggestionButton" class="auto-summary-suggestion h-2 absolute" ref="autosuggestionbar" @click="showPromptsUI"></div>
        </div>
      </div>
    </div>

  </vue-draggable-resizable>
</template>

<script>
import { nextTick } from 'process';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'video',
  props: ['dragAreaWidth', 'w', 'h', 'x', 'y'],
  data(){
    return{
      playerVars: {
        cc_load_policy: 1,
        iv_load_policy: 3,
        controls: 0,
        playsinline: 1,
        rel: 0,
        modestbranding: 1,
        autoplay: 1,
        widgetid: 1,
        allowfullscreen: 1
      },
      playerState: false,
      dragMargin: 16,
      total: 0,
      time: 0,
      playerTimeDifference: 0,
      interval: null,
      draggable: true,
      runCalc: true,
      leftBarWidth: 0,
      rightBarWidth: 0,
      topHeigth: 0,
      documentWidth: 0,
      documentHeight: 0,
      showSubElement: true,
      showSuggestionButton: true,
      autoSummarizeData: {
        duration: 10*60
      },
      showPrompts: false
    }
  },
  methods: {
    ...mapMutations({
      setDirectVideoScreenshots: 'videoDetails/setDirectVideoScreenshots',
    }),
    async setBackVideo(){
      const time = await this.player.getCurrentTime();
      this.player.seekTo(time - 15)
    },
    enableSubElems(arg){
      this.setDirectVideoScreenshots(arg.detail.screenshot)
      this.showSubElement = true
    },
    screencap() {
      this.showSubElement = false

      setTimeout(() => {
        let event = document.createEvent('Event');
        event.initEvent('doScreenshot');
        document.dispatchEvent(event);
      }, 100);
    },
    async showVideoDetails(){
      const time = await this.player.getCurrentTime()
      this.$emit('showVideoDetails', {
        show: true,
        video_id: this.video_id,
        timestamp: time,
        activetab: 2
      })
    },
    resizeStop(left, top, width, height){
      this.$emit('videoResize', {
        width,
        height,
        left,
        top
      })
    },
    async goToVideo() {
      await this.player.pauseVideo()
      const time = await this.player.getCurrentTime();

      window.open(`https://www.youtube.com/watch?v=${this.video_id}&t=${Math.floor(time)}s`, '_blank').focus();
    },
    dragging(left, top){
      if(left < -(this.leftBarWidth - 16)){
        return false
      }else if(left > (this.documentWidth - this.w - this.rightBarWidth - 16)){
        return false
      }else if(top < -(this.topHeigth - 16)){
        return false
      }else if(top > this.documentHeight - this.h - this.topHeigth - 16){
        return false
      }else{
        this.$emit('videoDragged', {
          left,
          top
        })
      }
    },
    async rangeStop(){
      this.draggable = false
      this.runCalc = false
      this.playerState = true
      await this.player.pauseVideo()
      clearInterval(this.interval)
    },
    async rangePlay(){
      this.runCalc = true
      this.playerState = false
      await this.player.playVideo()
      this.draggable = true
    },
    async videoRange(event){
      let newTimestamp = (event.target._value * this.total) / 100
      this.draggable = false
      this.player.seekTo(newTimestamp)
      this.draggable = true
    },
    async calc(){
      this.time = await this.player.getCurrentTime();
      this.playerTimeDifference = (this.time / this.total) * 100;
      this.playerTimeDifference = Math.round(this.playerTimeDifference)
    },
    async onPlayerReady(){
      this.total = await this.player.getDuration();
      await this.calc()
    },
    async onPlayerStateChange(){
      this.total = await this.player.getDuration();
      this.interval = setInterval(async () => {
        if (this.playerTimeDifference == 100) {
          clearInterval(this.interval)
        }

        if (this.runCalc) {
          await this.calc()
        }

      }, 1000);
    },
    async playPause(){
      this.playerState = !this.playerState

      if (this.playerState) {
        await this.player.pauseVideo()
      } else { 
        await this.player.playVideo()
      }
    },
    closeVideo(){
      this.$emit('closeVideo')
    },
    showPromptsUI() {
      this.showPrompts = true;
    },
    autoSummarize() {
      console.log(this)
      this.$parent.showAutoSummary()
      // this.$emit('showAutoSummary')
    },
    // calcTiming(totalSeconds, currentTimestamp) {
    //   return [currentTimestamp - (totalSeconds/2), currentTimestamp + (totalSeconds/2)]
    // },
    showSuggestion(e) {
      const secondsSummary = 60*10;
      const seconds = Math.floor(this.total);
      // const calculatedTime = this.calcTiming(secondsSummary, seconds);

      // console.log(calculatedTime);


      this.showSuggestionButton = true;

      const playerDimensions = this.$refs.autosummarybar.getBoundingClientRect()
      const playerPosLeft = playerDimensions.left;

      const widthSummary = (playerDimensions.width/seconds) * secondsSummary;

      nextTick(() => {
        let marginPos = e.screenX - playerPosLeft - (widthSummary/2);

        this.$refs.autosuggestionbar.style.minWidth = `${ seconds <= secondsSummary ? playerDimensions.width : widthSummary}px`
        if (marginPos + widthSummary < playerDimensions.width) {
          this.$refs.autosuggestionbar.style.marginLeft =  `${marginPos <= 0 ? 0 : marginPos }px`
        } 
      })
    },
    hideSuggestion() {
      this.showSuggestionButton = false;
    }
  },
  computed: {
    ...mapGetters({
      video_id: 'search/video_id',
      timestamp: 'search/timestamp',
      detailsState: 'search/detailsState',
      video: 'videoDetails/video',
    }),
    player() { 
      return this.$refs.youtube.player 
    }
  },
  watch:{
    video_id(){ 
      this.playerState = false 
    }
  },
  mounted() {
    if (this.timestamp) {
      this.player.seekTo(this.timestamp)
    }
    this.$refs.youtubeParent.addEventListener('click', this.playPause, false);
    this.$refs.planeWrap.$el.click()

    this.leftBarWidth = document.querySelector('.left-bar_js').offsetWidth
    this.rightBarWidth = document.querySelector('.right-bar_js').offsetWidth
    this.topHeigth = document.querySelector('.header_js').offsetHeight
    this.topHeigth += document.querySelector('.searchbar_js').offsetHeight
    this.documentWidth = document.querySelector('.app_js').offsetWidth
    this.documentHeight = document.querySelector('.app_js').offsetHeight

    document.addEventListener("doScreenshotDone", this.enableSubElems);
  },
  beforeDestroy(){
    this.$refs.youtubeParent.removeEventListener('click', this.playPause, false);
    document.removeEventListener("doScreenshotDone", this.enableSubElems);
    clearInterval(this.interval)
  }
}
</script>

<style lang="scss" scoped>
.border-dgray {
  border: 1px solid #323232;
}

.progressBarWrap{
  opacity: 0;
}
.planeWrap{
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  position: absolute;
  background: #464646;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px 0px rgb(69, 69, 69);
  z-index: 999!important;
  width: 480px;
  height: 270px;
  &:hover{
    .progressBarWrap{
      opacity: 1;
    }
  }
}

.dragArea{
  position: absolute;
  width: 100%;
  height: 30px;
  cursor: all-scroll;
  z-index: 2;
}

input[type=range] {
  height: 15px;
  -webkit-appearance: none;
  margin: 0;
  width: 100%;
  z-index: 20;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #969696;
  border-radius: 12px;
  border: 0px solid #FFFFFF;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  height: 22px;
  width: 22px;
  border-radius: 27px;
  background: #FFFFFF;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6.5px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #969696;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #969696;
  border-radius: 12px;
  border: 0px solid #FFFFFF;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #000000;
  height: 22px;
  width: 22px;
  border-radius: 27px;
  background: #FFFFFF;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #969696;
  border: 0px solid #FFFFFF;
  border-radius: 24px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-fill-upper {
  background: #969696;
  border: 0px solid #FFFFFF;
  border-radius: 24px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  height: 22px;
  width: 22px;
  border-radius: 27px;
  background: #FFFFFF;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #969696;
}
input[type=range]:focus::-ms-fill-upper {
  background: #969696;
}
.move-back{
  &:hover{
    opacity: 1;
  }
}

.auto-summary-bg {
  background: linear-gradient(89.48deg, #FEFBB8 9.85%, rgba(184, 254, 229, 0.94) 34.55%, #E1CFFD 55.99%, #F4B8FE 73.7%, #FEB8C9 86.75%);
}

.auto-summary-suggestion {
  // border: 1px dashed orange;
  // border-color: orange;
  animation: linearGradientMove 1s infinite linear;
  background: 
    linear-gradient(90deg, rgb(255, 255, 255) 50%, transparent 0) repeat-x,
    linear-gradient(90deg, rgb(255, 255, 255) 50%, transparent 0) repeat-x,
    linear-gradient(0deg, rgb(255, 255, 255) 50%, transparent 0) repeat-y,
    linear-gradient(0deg, rgb(255, 255, 255) 50%, transparent 0) repeat-y;
  background-size: 4px 1px, 4px 1px, 1px 4px, 1px 4px;
  background-position: 0 0, 0 100%, 0 0, 100% 0;
}

@keyframes linearGradientMove {
    100% {
      background-position: 4px 0, -4px 100%, 0 -4px, 100% 4px;
    }
}

.prompts {
  -webkit-mask-image: linear-gradient(272deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 23%);
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 23%);
}
</style>