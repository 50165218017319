<template>
  <div>
    <Searchbar />
    <div style="display: flex">
      <div class="results" ref="results" :class="{ 'w-9/12': isShowVideoDetail, 'w-full': !isShowVideoDetail}">
        <div v-if="loading" class="w-full fixed flex items-center loading flex-col justify-center" style="height: calc(100vh - 6rem)">
          <img class="fixed z-40" :src="require('../../assets/icons/raditubelogo.svg')" alt=""> 
        </div>

        <div v-if="autoSummaryVisible" class="flex fixed bg-black w-80 z-50 right-16 top-28 flex-col auto-suggestions">
          <div class="auto-first-suggestion m-4 p-4 text-sm">
            <span class="font-sansDemi text-sm">These are the three most important points between minute 0 and 10.</span><br/>

            <ul class="mt-2">
            <li class="text-sm"><span class="font-monoDemi text-sm bg-gray-100 text-gray-700 rounded px-1">2.28</span> Peter Thiel is funding a network of right-wing influencers and thinkers, many of whom are familiar names.</li>
            <li class="text-sm"><span class="font-monoDemi  text-sm bg-gray-100 text-gray-700 rounded px-1">3.15</span>  The Good Ol' Boys were mentioned by name in the article and came off very well.</li>
            <li class="text-sm"><span class="font-monoDemi text-sm bg-gray-100 text-gray-700 rounded px-1">5.00</span>  Curtis Yarvin is a central figure in the piece, and his writing has had a lot of energy behind it.</li>
            </ul>
          </div>
        </div>

        <Video 
          v-if="showVideo"
          :dragAreaWidth="dragAreaWidth" 
          :w="videoWidth" 
          :h="videoHeight"
          :x="videoPositionX"
          :y="videoPositionY"
          @videoResize="videoResize"
          @videoDragged="videoDragged"
          @closeVideo="closeVideo"
        />

        <!-- @showVideoDetails="showVideoDetails" -->

        <SearchError v-if="lines.length === 0 && !loading" type="zeroResults" />

        <DynamicScroller
          v-show="lines.length"
          :items="lines"
          :min-item-size="54"
          class="scroller"
          key-field="line_id"
          @scroll.native="onScroll"
          ref='scroller'
        >
          <template v-slot="{ item, index, active }">
            <DynamicScrollerItem
              :item="item"
              :active="active"
              :data-index="index"
            >

            <SingleLine 
              v-if="item.videoId"
              :id="item.videoId" 
              :linetext="item.textHTML" 
              :channel_name="item.user"
              :channel_id="item.channelId"
              :thumb="item.thumb"
              :date="item.date"
              :online="item.online"
              :lineObj="item"
              :narrativeTracing="narrativeTracing"
              v-on:showAutoSummary="showAutoSummary"
              :isShowNewVideo="isShowNameVideo(item, lines[index-1])"
              :isShowNameAvatar="isShowNameAvatar(item, lines[index - 1])"
            />

            <!-- @showVideoDetails="showVideoDetails" -->
            
            </DynamicScrollerItem>
          </template>
        </DynamicScroller>

        <div v-if="lines.length" class="flex fixed bottom-2 h-10 z-20 mx-2 rounded-3xl" style="background: rgb(51, 51, 51, .8); backdrop-filter: blur(10px); width: calc(100% - 7rem)">
          <div class="text-white leading-12 mx-1.5">
            <a :href="`https://www.youtube.com/watch?v=${videoIdState}`" target="_blank">
              <div v-if="showVideo" class="flex mt-1.5 items-center h-7 font-monoDemi border inline-block text-xs px-3 rounded-2xl whitespace-nowrap" style="border-color: rgb(137, 137, 137);">Watch on YouTube</div>
            </a>
          </div>
          <div class="text-white leading-12">
            <button @click="enableNarrativeTracing()" class="flex mt-1.5 items-center h-7 font-monoDemi border inline-block text-xs px-3 rounded-2xl whitespace-nowrap" style="border-color: rgb(137, 137, 137);">Narrative Tracing</button>
          </div>

          <div v-if="showVideo" class="ml-2 px-2 rounded-full flex" style="background-color: #666;">

            <div class="text-white leading-12 flex mt-1.5 items-center h-7 font-monoDemi border inline-block text-xs rounded-2xl whitespace-nowrap" style="border-color: rgb(137, 137, 137);">
              <span class="h-7 w-7 rounded-full bg-cover mr-2" style="background-image: url('https://yt3.ggpht.com/ytc/AKedOLSouowiAe6jGrv2PkoqoXBcrD9KqUHpsaKmM3qIBQ')"></span>
              <span class="text-xs mr-3">.</span>
            </div>

            <div class="text-white leading-12 flex mt-1.5 ml-2 items-center h-7 font-monoDemi border inline-block text-xs rounded-2xl whitespace-nowrap" style="border-color: rgb(137, 137, 137);">
              <span class="h-7 w-7 rounded-full bg-cover mr-2" style="background-image: url('https://yt3.ggpht.com/ytc/AKedOLSouowiAe6jGrv2PkoqoXBcrD9KqUHpsaKmM3qIBQ')"></span>
              <span class="text-xs mr-3">.</span>
            </div>

            <div class="text-white leading-12 w-7 h-7 flex mt-1.5 ml-2 items-center font-monoDemi border inline-block text-xs rounded-2xl whitespace-nowrap" style="border-color: rgb(137, 137, 137);">
              <span class="text-xs h-7 w-7 text-center leading-6">+</span>
            </div>

          </div>
        </div>
      </div>

    <NarrativeTracing v-if="narrativeTracing" />

    <!-- <VideoDetail 
      v-show="isShowVideoDetail" 
      :video="video" 
      :transcription="videoTranscription"
      :selectOptions="userMetaLabels"
      :timestamp="timestamp"
      :notes="videoNotes"
      :users="videoUsers"
      @close="showVideoDetail({ show: false, video_id: '' })"
    /> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { eventBus } from '@/main'

import Video from '@/components/search/Video'
import Searchbar from '@/components/search/Searchbar'
import SearchError from '@/components/search/SearchError'
import SingleLine from '@/components/search/SingleLine'
import NarrativeTracing from './NarrativeTracing.vue'
// import VideoDetail from '@/components/video-detail/VideoDetail.vue'

export default {
  name: 'Search',
  props: {
    items: Array,
  },
  components: {
    Video, Searchbar, SingleLine, NarrativeTracing, SearchError
    // VideoDetail
  },
  data: () => {
    return {
      dragAreaWidth: 0,
      dragAreaFromTop: 0,
      videoWidth: 480,
      videoHeight: 270,
      videoPositionX: 16,
      videoPositionY: 16,
      narrativeTracing: false,
      autoSummaryVisible: false,
      zeroChannels: true,
    }
  },
  computed: {
    ...mapGetters({
      showVideo: 'search/showVideo',
      loading: 'search/loading',
      noResults: 'search/noResults',
      lines: 'search/lines',
      isShowVideoDetail: 'search/isShowVideoDetail',
      closePiPScroll: 'settings/close_pip_scroll',
      closeDetailsScroll: 'settings/close_details_scroll',
      video: 'videoDetails/video',
      timestamp: 'search/timestamp',
      userMetaLabels: 'general/userMetaLabels',
      videoTranscription: 'videoDetails/videoTranscription',
      videoNotes: 'videoDetails/videoNotes',
      videoUsers: 'videoDetails/videoUsers',
      detailsState: 'search/detailsState',
      videoIdState: 'search/video_id'
    })
  },
  methods: {
    ...mapActions({
      activateVideo: 'search/activateVideo',
      showVideoDetail: 'search/showVideoDetail',
      fetchLines: 'search/fetchLines',
      getDirectVideoDetails: 'videoDetails/getDirectVideoDetails',
      getDirectVideoTranscription: 'videoDetails/getDirectVideoTranscription',
      getDirectVideoNotes: 'videoDetails/getDirectVideoNotes',
      getDirectVideoUsers: 'videoDetails/getDirectVideoUsers',
    }),
    ...mapMutations({
      setDirectVideo: 'videoDetails/setDirectVideo',
      setFrom: 'search/setFrom'
    }),
    isShowNameAvatar(item, prevItem){
      if(prevItem){
        return item.channelId != prevItem.channelId && item.user != prevItem.user
      } else return true
    },
    isShowNameVideo(item, prevItem){
      if(prevItem){
        return item.videoId != prevItem.videoId
      } else return true
    },
    videoResize({ left, top, width, height }){
      this.videoWidth = width
      this.videoHeight = height
      this.videoDragged({ left, top })
    },
    videoDragged({ left, top }){
      this.videoPositionX = left
      this.videoPositionY = top
    },
    enableNarrativeTracing() {
      this.narrativeTracing = !this.narrativeTracing;



      
    },
    // async showVideoDetails(arg){
    //   this.setDirectVideo(null)
    //   this.showVideoDetail(arg)
    //   if(arg.activetab){
    //     eventBus.$emit('setActiveTab', arg.activetab)
    //   }
    //   if(arg.show) {
    //     await this.getDirectVideoDetails(arg.video_id)
    //     await this.getDirectVideoTranscription(arg.video_id)
    //     await this.getDirectVideoNotes({ id: arg.video_id })
    //     await this.getDirectVideoUsers()
    //   }
    // },
    closeVideo(){
      this.activateVideo({ video_id: '', timestamp: '', show: false })
      this.showVideoDetail({ show: false, video_id: '' })
    },
    showAutoSummary() {
      this.autoSummaryVisible = true;
    },
    hideVideo(){
      if (this.closePiPScroll) {
        this.activateVideo({
          video_id: "",
          timestamp: "",
          show: false
        })
      }
    },

    // hideVideoDetails(){
    //   if (this.closeDetailsScroll) {
    //     this.showVideoDetail({
    //       show: false,
    //       video_id: ""
    //     })
    //   }
    // },
    async onScroll(el) {
      console.log('on scroll')
      this.hideVideo()
      this.autoSummaryVisible = false;

      if ( el.srcElement.offsetHeight + el.srcElement.scrollTop >= el.srcElement.scrollHeight ) {
        await this.fetchLines({
          followup: true
        })
      }
    },
    disableVideoWrap(){
      this.hideVideo()
      // this.hideVideoDetails()
    },
    async scrollEnd(arg){

      const diff = arg - this.lines.length
      this.setFrom(this.lines.length)
      await this.fetchLines({
        size: diff
      })
      this.scrolling(arg)
      
    },
    scrolling(arg){
      this.$refs.scroller.scrollToItem(arg)
    } 
  },
  mounted(){
    let dragArea = document.querySelector('.vue-recycle-scroller')
    this.dragAreaWidth = dragArea.clientWidth

    console.log(this.$store.getters)

    eventBus.$on('scrollEnd', this.scrollEnd)
    eventBus.$on('scrolling', this.scrolling)
    eventBus.$emit('setSearchTerm', this.$route.params.id, this.$route.params.bid)
  },
  beforeDestroy(){
    eventBus.$off('scrollEnd', this.scrollEnd)
    eventBus.$off('scrolling', this.scrolling)
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      if(vm.mixin_route_guard(vm)){
        next()
      }else{
        next('/')
      }
    })
  },
  beforeRouteUpdate(to, from, next) {
    eventBus.$emit('setSearchTerm', to.params.id, to.params.bid)
    next()
  },
}
</script>

<style scoped>
.scroller {
  height: calc(100vh - 6rem);
}

.auto-suggestions {
  background: rgba(255, 255, 255, 0.74);
  border: 1px solid #DEDEDE;
  backdrop-filter: blur(5.5px);
  border-radius: 10px;
  min-height: calc(100vh - 11rem)
}

.auto-first-suggestion {
  background: #EAEAEA;
  border: 1px solid #D5D5D5;
  border-radius: 8px;

}
</style>